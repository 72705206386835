<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-3">Consider the <b>unbalanced</b> reaction shown below:</p>

      <p class="mb-5 pl-8">
        <chemical-latex content="Fe^3+(aq) + H2(g) -> Fe^2+(aq) + H+(aq)" />
      </p>

      <p class="mb-2">a) Balance the cathode half reaction.</p>

      <p class="mb-5">
        <chemical-notation-input
          v-model="inputs.cathodeRxn"
          dense
          :show-note="false"
          :disabled="!allowEditing"
          disable-validation
        />
      </p>

      <p class="mb-2">b) Balance the anode half reaction.</p>

      <p class="mb-5">
        <chemical-notation-input
          v-model="inputs.anodeRxn"
          dense
          :show-note="false"
          :disabled="!allowEditing"
          disable-validation
        />
      </p>

      <p class="mb-2">c) Write the balanced equation for the cell reaction.</p>

      <p class="mb-5">
        <chemical-notation-input
          v-model="inputs.cellRxn"
          dense
          :show-note="false"
          :disabled="!allowEditing"
          disable-validation
        />
      </p>

      <p class="mb-5">
        d) Describe the cell using standard cell notation. Note that you can insert the vertical
        lines for phase boundaries and the salt bridge using the pipe character "|" which shares a
        key with the backslash "\", which can be found above the Enter/Return key on your keyboard.
      </p>

      <p class="mb-5">
        <chemical-notation-input
          v-model="inputs.cellNotation"
          dense
          :show-note="false"
          :disabled="!allowEditing"
          disable-validation
        />
      </p>

      <p class="mb-2">e) Calculate the standard cell potential for this system.</p>

      <calculation-input
        v-model="inputs.Ecell"
        class="mb-5"
        prepend-text="$\text{E}^\circ_\text{cell}:$"
        append-text="$\text{V}$"
        :disabled="!allowEditing"
      />

      <p class="mb-2">
        f) Calculate the Gibbs free energy change,
        <stemble-latex content="$\Delta\text{G}^\circ_{\text{rxn}},$" />
        for the reaction in kJ.
      </p>

      <calculation-input
        v-model="inputs.deltaG"
        class="mb-6"
        prepend-text="$\Delta\text{G}^\circ_{\text{rxn}}:$"
        append-text="$\text{kJ}$"
        :disabled="!allowEditing"
      />
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import ChemicalNotationInput from '@/chemical-equations/components/ChemicalNotationInput';
import ChemicalLatex from '../displayers/ChemicalLatex';
import StembleLatex from '@/tasks/components/StembleLatex';
import CalculationInput from '@/tasks/components/inputs/CalculationInput.vue';

export default {
  name: 'cpp1220Exam3Q7',
  components: {
    CalculationInput,
    ChemicalNotationInput,
    ChemicalLatex,
    StembleLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        cathodeRxn: null,
        anodeRxn: null,
        cellRxn: null,
        cellNotation: null,
        Ecell: null,
        deltaG: null,
      },
    };
  },
};
</script>
